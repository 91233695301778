import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, Box, useMediaQuery } from '@mui/material';
import ContactForm from '../components/ContactForm';

const useStyles = makeStyles()((theme) => ({
  contactSection: {
    backgroundColor: '#191919',
    padding: theme.spacing(8, 3),
    display: 'flex',
    justifyContent: 'space-around',
    color: '#adadad'
  },
  contactDetails: {
    width: '50%',
    marginTop: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }, 
  mobileContactSection: {
    backgroundColor: '#191919',
    padding: theme.spacing(8, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#adadad'
  },
}));

const ContactUs = () => {
  const { classes } = useStyles();
  const isMobile = useMediaQuery('(max-width: 950px)');

  if (isMobile) {
    return (
      <Box className={classes.mobileContactSection}>
        <Box>
          <Typography variant='h4' mb={2}>Contact Us</Typography>
        </Box>
        <ContactForm />
      </Box>
    )
  }
  return (
    <Box className={classes.contactSection}>
      <Box className={classes.contactDetails}>
        <Typography variant="h3" style={{ marginBottom: '20px' }}>Contact Us</Typography>
        <Typography variant="body1" style={{ marginBottom: '50px' }}>Ogden, UT 84401</Typography>
        <Typography variant="body1">team@triactinpartners.com</Typography>
      </Box>
      <ContactForm />
    </Box>
  );
}

export default ContactUs;
