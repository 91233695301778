import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import Markets from './pages/Markets';
import About from './pages/About';
import Footer from './components/Footer'
import UtahHighlight from './pages/MarketHighlights/UtahHighlight';
import MemphisHighlight from './pages/MarketHighlights/MemphisHighlight';
import MobileHighlight from './pages/MarketHighlights/MobileHighlight';
import CharlotteHighlight from './pages/MarketHighlights/CharlotteHighlight';

function App() {
  return (
    <Router>
      <div style={{ backgroundColor: "#000" }}>
        <header>
          <Navigation />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/markets" element={<Markets />} />
            <Route path="/utah" element={<UtahHighlight />} />
            <Route path="/mobile" element={<MobileHighlight />} />
            <Route path="/memphis" element={<MemphisHighlight />} />
            <Route path="/charlotte" element={<CharlotteHighlight />} />
          </Routes>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </Router>
  );
}

export default App;