import { Typography, Avatar, Box, useMediaQuery } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import ChaseHeadshot from '../images/ChaseHeadshot.jpg'
import MitchHeadshot from '../images/MitchHeadshot.jpg';
import BradHeadshot from '../images/BradHeadshot.jpeg';
import ScottHeadshot from '../images/ScottHeadshot.jpg';
import IconOnly from '../images/IconOnly.png';

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    width: '100%',
    color: '#adadad',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoContainer: {
    width: '100%',
    backgroundImage: `url(${IconOnly})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(8, 3),
  },
  aboutContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    width: '50%',
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 2)
  },
  aboutText: {
    marginBottom: '10px',
    padding: '10px 0px',
    textAlign: 'center',
  },
  meetTheTeamContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '100px',
  },
  teamHeader: {
    marginTop: '100px',
  },
  teamMembersContainer: {
    marginTop: '100px',
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  teamMember: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    marginBottom: '20px',
  },
  customAvatar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: '25px'
  },
  mobileLogoContainer: {
    backgroundImage: `url(${IconOnly})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  mobileTeamMembersContainer: {
    marginTop: '50px',
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  mobileTeamMember: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '15px 0px'
  },
  mobileAboutContainer: {
    width: '80%',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    padding: theme.spacing(2, 2),
    margin: theme.spacing(2, 1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }, 
  mobileTeamHeader: {
    marginTop: '50px'
  }
}))

const About = () => {
  const { classes } = useStyles();
  const isMobile = useMediaQuery('(max-width: 950px)');

  if (isMobile) {
    return (
      <Box className={classes.mainContainer}>
        <Box className={classes.mobileLogoContainer}>
          <Box className={classes.mobileAboutContainer}>
            <Typography variant="h4" style={{ marginBottom: '25px' }}>About Our Business</Typography>
            <Typography variant='body1' className={classes.aboutText}>TriActin Partners specializes in connecting investors with lucrative properties that align with their goals. With our industry expertise, rigorous research, and extensive network, we identify prime opportunities. Our dedicated team delivers exceptional service, providing personalized assistance throughout the investment process. Whether you're a seasoned investor or just starting out, partner with TriActin Partners to make informed investment decisions and unlock a world of real estate possibilities.</Typography>
          </Box>
        </Box>
        <Typography variant="h4" className={classes.mobileTeamHeader}>Meet the Team</Typography>
        <Box className={classes.mobileTeamMembersContainer}>
          <Box className={classes.mobileTeamMember}>
            <Avatar alt="Chase" sx={{ width: 150, height: 150 }} className={classes.avatar}>
              <img src={ChaseHeadshot} alt="Chase" style={{ position: 'absolute', top: '85%', left: '49%', transform: 'translate(-50%, -50%)', maxHeight: '185%' }} />
            </Avatar>
            <Typography variant="h5">Chase Carter</Typography>
            <Typography variant="subtitle1">Head of Real Estate</Typography>
          </Box>
          <Box className={classes.mobileTeamMember}>
            <Avatar alt="Brad" sx={{ width: 150, height: 150 }} className={classes.avatar}>
              <img src={BradHeadshot} alt="Brad" style={{ position: 'absolute', top: '73%', left: '54%', transform: 'translate(-50%, -50%)', maxHeight: '195%' }} />
            </Avatar>
            <Typography variant="h5">Brad Stratford</Typography>
            <Typography variant="subtitle1">Head of Property Management</Typography>
          </Box>
          <Box className={classes.mobileTeamMember}>
            <Avatar alt="Mitch" src={MitchHeadshot} sx={{ width: 150, height: 150 }} className={classes.avatar} />
            <Typography variant="h5">Mitch Moore</Typography>
            <Typography variant="subtitle1">Head of Operations</Typography>
          </Box>
          <Box className={classes.mobileTeamMember}>
            <Avatar alt="Scott" src={ScottHeadshot} sx={{ width: 150, height: 150 }} className={classes.avatar} />
            <Typography variant="h5">Scott Welch</Typography>
            <Typography variant="subtitle1">Head of Finance</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.logoContainer}>
        <Box className={classes.aboutContainer}>
          <Typography variant="h4" style={{ marginBottom: '25px' }}>About Our Business</Typography>
          <Typography variant='body1' className={classes.aboutText}>At TriActin Partners, we specialize in connecting astute investors with lucrative investment properties. With our extensive industry knowledge, rigorous research, and an expansive network, we diligently identify prime opportunities that align with your investment goals.</Typography>
          <Typography variant='body1' className={classes.aboutText}>Our dedicated team of professionals is committed to delivering exceptional service, ensuring that you receive personalized assistance throughout the entire investment process. Whether you're a seasoned investor or just starting your real estate journey, TriActin Partners is here to provide you with the expertise and support you need to make informed investment decisions. Partner with us today and unlock a world of real estate investment possibilities.</Typography>
        </Box>
      </Box>
      <Box className={classes.meetTheTeamContainer}>
        <Typography variant="h4" className={classes.teamHeader}>Meet the Team</Typography>
        <Box className={classes.teamMembersContainer}>
          <Box className={classes.teamMember}>
            <Avatar alt="Chase" sx={{ width: 150, height: 150 }} className={classes.avatar}>
              <img src={ChaseHeadshot} alt="Chase" style={{ position: 'absolute', top: '85%', left: '49%', transform: 'translate(-50%, -50%)', maxHeight: '185%' }} />
            </Avatar>
            <Typography variant="h5">Chase Carter</Typography>
            <Typography variant='subtitle1'>Head of Real Estate</Typography>
          </Box>
          <Box className={classes.teamMember}>
            <Avatar alt="Brad" sx={{ width: 150, height: 150 }} className={classes.avatar}>
              <img src={BradHeadshot} alt="Brad" style={{ position: 'absolute', top: '73%', left: '54%', transform: 'translate(-50%, -50%)', maxHeight: '195%' }} />
            </Avatar>
            <Typography variant="h5">Brad Stratford</Typography>
            <Typography variant='subtitle1'>Head of Property Management</Typography>
          </Box>
          <Box className={classes.customAvatar}>
            <Avatar alt="Mitch" src={MitchHeadshot} sx={{ width: 150, height: 150 }} className={classes.avatar} />
            <Typography variant="h5">Mitch Moore</Typography>
            <Typography variant='subtitle1'>Head of Operations</Typography>
          </Box>
          <Box className={classes.teamMember}>
            <Avatar alt="Scott" src={ScottHeadshot} sx={{ width: 150, height: 150 }} className={classes.avatar} />
            <Typography variant="h5">Scott Welch</Typography>
            <Typography variant='subtitle1'>Head of Finance</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default About;
