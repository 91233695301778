import { Box, Typography } from '@mui/material';
import React from 'react';
// import { makeStyles } from 'tss-react/mui';

// const useStyles = makeStyles()(() => ({

// }));

const UtahHighlight = () => {
    // const { classes } = useStyles();
    return (
        <Box>
            <Typography>UTAH</Typography>
            <Box>
                <Typography>AT A GLANCE</Typography>
            </Box>
        </Box>
    )
}

export default UtahHighlight;