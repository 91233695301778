import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './global.css';
import "@fontsource/barlow";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
      fontFamily: 'Barlow', 
    },
  });

ReactDOM.render(<ThemeProvider theme={theme}><App /></ThemeProvider>, document.getElementById('root'));
