import React from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';
import UtahCard from '../components/Cards/UtahCard';
import MemphisCard from '../components/Cards/MemphisCard';
import MobileCard from '../components/Cards/MobileCard';
import CharlotteCard from '../components/Cards/CharlotteCard';
import OrlandoCard from '../components/Cards/OrlandoCard';
import StLouisCard from '../components/Cards/StLouisCard';

const useStyles = makeStyles()(() => ({
  marketsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  marketsBox: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  card: {
    display: 'flex',
    flexWrap: 'wrap',
  }
}))

function Markets() {
  const { classes } = useStyles()


  return (
    <div className={classes.marketsContainer}>
      <Box className={classes.marketsBox}>
          <UtahCard />
          <MemphisCard />
          <CharlotteCard />
          <MobileCard />
          <OrlandoCard />
          <StLouisCard />
      </Box>
      <Outlet />
    </div>
  );
}

export default Markets;
