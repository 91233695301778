import React, { useState } from 'react';
import { AppBar, Toolbar, Drawer, useMediaQuery, Link, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NavLogo from '../images/NavLogo.png';

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginRight: drawerWidth,
        },
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#000',
        color: '#adadad'
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    logoContainer: {
        width: '100%',
        height: '75%',
        margin: '20px 0px',
        backgroundImage: `url(${NavLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center',
        backgroundSize: 'contain',
        display: 'flex',
        justifyContent: 'flex-start',
    }
}));

const drawerWidth = 240;

const Navigation = () => {
    const { classes } = useStyles();
    const isScreenSmaller = useMediaQuery('(max-width:650px)');
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerOpen = () => {
        setDrawerOpen(!isDrawerOpen)
    };

    if (isScreenSmaller) {
        return (
            <ThemeProvider theme={createTheme()}>
                <div className={classes.root}>
                    <AppBar position="static" className={classes.appBar} style={{ backgroundColor: '#000', color: '#adadad', padding: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
                            <Toolbar style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>
                                <Link href="/" underline="none" className={classes.logoContainer}>
                                    <div style={{ height: '50px' }}></div>
                                </Link>
                            </Toolbar>
                            {!isDrawerOpen &&
                                <IconButton
                                    className={classes.menuButton}
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                >
                                    <MenuIcon style={{ color: '#adadad' }} />
                                </IconButton>
                            }
                        </div>
                        <nav className={classes.drawer} aria-label="navigation" >
                            <Drawer
                                variant="persistent"
                                anchor="right"
                                open={isDrawerOpen}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                style={{ backgroundColor: '#000' }}
                            >
                                <div className={classes.drawerContainer} >
                                    {isDrawerOpen && (
                                        <IconButton
                                            onClick={handleDrawerOpen}
                                            style={{ alignSelf: 'flex-end', color: '#fff' }}
                                        >
                                            <ChevronLeftIcon style={{ color: '#adadad', paddingTop: '5px' }} />
                                        </IconButton>
                                    )}
                                    <div style={{ width: '100%' }}>
                                        <div style={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                            <Link href="/" underline="none" color="inherit" style={{ margin: '20px 0px 20px 15px' }}>Home</Link>
                                            <Link href="/markets" underline="none" color="inherit" style={{ marginLeft: '15px', marginBottom: '20px' }}>Markets</Link>
                                            <Link href="/about" underline="none" color="inherit" style={{ marginLeft: '15px', marginBottom: '20px' }}>About</Link>
                                            <Link href="/contact" underline="none" color="inherit" style={{ marginLeft: '15px', }}>Contact Us</Link>
                                        </div>
                                    </div>
                                </div>
                            </Drawer>
                        </nav>
                    </AppBar>
                </div>
            </ThemeProvider>
        );
    }


    return (
        <AppBar position="static" style={{ backgroundColor: '#000', color: '#adadad', paddingLeft: '50px' }}>
            <Toolbar style={{ display: 'flex', flexDirection: 'column' }}>
                <Link href="/" underline="none" className={classes.logoContainer}>
                    <div style={{ height: '100px' }}></div>
                </Link>
                <div style={{ width: '100%', height: '25%', margin: '20px 0px' }}>
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-start' }}>
                        <Link href="/" underline="none" color="inherit" style={{ marginRight: '30px' }}>Home</Link>
                        <Link href="/markets" underline="none" color="inherit" style={{ marginRight: '30px' }}>Markets</Link>
                        <Link href="/about" underline="none" color="inherit" style={{ marginRight: '30px' }}>About</Link>
                        <Link href="/contact" underline="none" color="inherit">Contact Us</Link>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default Navigation;
