import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, Box } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
    footer: {
        color: '#adadad',
        padding: theme.spacing(2),
        textAlign: 'center',
      }
}))

const Footer = () => {
    const { classes } = useStyles();
  
    return (
      <Box className={classes.footer}>
        <Typography style={{marginBottom: '10px'}}>TriActin Partners, LLC | All Rights Reserved</Typography>
        <Typography variant="body2" color="inherit">
          &copy; {new Date().getFullYear()} by TriActin Partners, LLC.
        </Typography>
      </Box>
    );
  };

  export default Footer;