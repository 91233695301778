import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Card, CardContent, CardMedia, Typography, CardActionArea } from '@mui/material';
import StLouis from '../../images/stlouis.jpg';
// import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles()(() => ({
    cardContent: {
        backgroundColor: '#adadad',
        position: 'relative',
    },
    cardContainer: {
        margin: '15px',
    },
    learnMoreButton: {
        width: '50%',
        position: 'absolute',
        top: '50%',
        left: '60%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 0,
        opacity: 0,
        transition: 'opacity 0.3s ease',
    },
    overlayVisible: {
        opacity: 1,
    },
    learnButton: {
        backgroundColor: '#fff',
        color: '#000',
        '&:hover': {
            backgroundColor: '#adadad',
        },
    }
}));

const StLouisCard = () => {
    const { classes } = useStyles();
    //   const [isHovered, setIsHovered] = React.useState(false);

    //   const handleMouseEnter = () => {
    //     setIsHovered(true);
    //   };

    //   const handleMouseLeave = () => {
    //     setIsHovered(false);
    //   };

    //   const navigate = useNavigate();
    //   const handleLearnMoreClick = () => {
    //     navigate('/StLouis');
    //   };

    return (
        <Card
            sx={{ maxWidth: 400 }}
            className={classes.cardContainer}
        //   onMouseEnter={handleMouseEnter}
        //   onMouseLeave={handleMouseLeave}
        >
            <CardActionArea>
                <CardMedia component="img" height="300" image={StLouis} alt="card" />
                <CardContent sx={{ minHeight: 100 }} className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="div">
                        StLouis
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Affordable cost of living, vibrant neighborhoods, thriving arts and music scene, and abundance of recreational opportunities along the iconic Mississippi River.          </Typography>
                    {/* {isHovered && (
            <CardActions className={classes.learnMoreButton}>
              <Button variant='contained' className={classes.learnButton} onClick={handleLearnMoreClick}>
                Learn More
              </Button>
            </CardActions>
          )} */}
                    {/* <div className={`${classes.overlay} ${isHovered ? classes.overlayVisible : ''}`}></div> */}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default StLouisCard;
