import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography, Button, Box, useMediaQuery } from '@mui/material';
import ContactUs from './ContactUs';
import { useNavigate } from 'react-router-dom';
import neighborhood from '../images/neighborhood.jpg';

const useStyles = makeStyles()((theme) => ({
  heroSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: `url(${neighborhood})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  heroContent: {
    backgroundColor: 'rgba(0, 0, 0, 0.95)',
    padding: theme.spacing(8, 3),
    textAlign: 'center',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(8, 2)
  },
  heroTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),
    color: '#adadad'
  },
  heroDescription: {
    color: '#FFF',
    marginBottom: theme.spacing(4),
  },
  heroButton: {
    width: '30%',
    backgroundColor: '#fff',
    color: '#000',
    '&:hover': {
      backgroundColor: '#adadad',
    },
  },
  mobileHeroSection: {
    width: '100%',
    padding: theme.spacing(10, 3),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${neighborhood})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  mobileHeroContent: {
    backgroundColor: 'rgba(0, 0, 0, 0.95)',
    padding: theme.spacing(8, 3),
    textAlign: 'center',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2, 1)
  },
  contactSection: {
    backgroundColor: '#191919',
    padding: theme.spacing(8, 3),
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#adadad'
  },
  contactDetails: {
    width: '50%',
    marginTop: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

const Home = () => {
  const { classes } = useStyles();
  const isMobile = useMediaQuery('(max-width: 950px)');

  const navigate = useNavigate();
  const handleGetStartedClick = () => {
    navigate('/contact');
  };

  if (isMobile) {
    return (
      <Box>
        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#191919' }}>
          <Box className={classes.mobileHeroSection}>
            <Box className={classes.mobileHeroContent}>
              <Typography variant="h4" className={classes.heroTitle}>
                Create Wealth Through Smart Real Estate Investments
              </Typography>
              <Typography variant="body1" className={classes.heroDescription}>
                Real estate investment opens up a wealth of opportunities, empowering both seasoned investors and newcomers alike. With its enticing returns and long-term stability, the real estate market serves as a pathway towards financial freedom and prosperity.      </Typography>
              <Button variant="contained" className={classes.heroButton} onClick={handleGetStartedClick}>
                Get Started
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography variant="h4"></Typography>
        </Box>
        <ContactUs />
      </Box>

    );
  }
  return (
    <Box>
      <Box style={{ width: '100%', display: 'flex' }}>
        <Box className={classes.heroSection}>
          <Box className={classes.heroContent}>
            <Typography variant="h4" className={classes.heroTitle}>
              Create Wealth Through Smart Real Estate Investments
            </Typography>
            <Typography variant="body1" className={classes.heroDescription}>
              Real estate investment opens up a wealth of opportunities, empowering both seasoned investors and newcomers alike. With its enticing returns and long-term stability, the real estate market serves as a pathway towards financial freedom and prosperity.      </Typography>
            <Button variant="contained" className={classes.heroButton} onClick={handleGetStartedClick}>
              Get Started
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography variant="h4"></Typography>
      </Box>
      <ContactUs />
    </Box>

  );
}

export default Home;
