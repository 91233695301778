import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fff'
  },
  formField: {
    width: '100%',
    color: '#adadad',
    '& .MuiInputBase-input': {
      color: '#adadad',
      borderBottom: '#adadad'
    },
    '& .MuiInputLabel-root': {
      color: '#adadad !important',
    },
    '& .MuiInput-root': {
      color: '#adadad !important',
      borderBottom: '#adadad !important'
    },
  },
  submitButton: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#fff',
    marginTop: '25px',
    color: '#000',
    '&:hover': {
      backgroundColor: '#adadad',
    }
  }
}));

const ContactForm = () => {
  const { classes } = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const formData = {
      name,
      email,
      phone,
      subject,
      message,
    };
  
    const accessToken = 'ya29.a0AWY7CkkgYlmNR9lu2GNqvmEMoYjFVr9SXOMQlTlvN6rSDIXvgtEfuxil-xF-2gMEt0Mo_KpCLnFCpYL-ppw4agCA-xq8Hh-8f7Abfm-Y-Ad56xMHfPdMdAtBLDhxTXshqOOmCtFqt1DACWRdAHHniidPvNmJaCgYKAQYSARISFQG1tDrpBAZpVsyqn8iqPV1iQ-3tCw0163'; 

    fetch('/.netlify/functions/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (response.ok) {
            alert('Form submitted successfully!');
            setName('');
            setEmail('');
            setPhone('');
            setSubject('');
            setMessage('');
          } else {
            throw new Error('Form submission failed.');
          }
        })
        .catch((error) => {
          alert(error.message);
        });
  };

  return (
    <form onSubmit={handleSubmit} name="contact-form" className={classes.container} data-netlify="true">
      <input type="hidden" name="contact-form" value="contact-form" />
      <TextField
        label="Name"
        required
        variant="standard"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className={classes.formField}
        margin="normal"
        InputProps={{ style: { color: '#fff' } }}
        InputLabelProps={{ style: { color: 'fff' } }}
        name="contact-form"
      />
      <TextField
        label="Email"
        type="email"
        variant="standard"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className={classes.formField}
        margin="normal"
        InputProps={{ style: { color: '#fff' } }}
        name="contact-form"
      />
      <TextField
        label="Phone Number"
        variant="standard"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        className={classes.formField}
        margin="normal"
        InputProps={{ style: { color: '#fff' } }}
        name="contact-form"
      />
      <TextField
        label="Subject"
        variant="standard"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        className={classes.formField}
        margin="normal"
        InputProps={{ style: { color: '#fff' } }}
        name="contact-form"
      />
      <TextField
        label="Message"
        multiline
        variant="standard"
        rows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className={classes.formField}
        margin="normal"
        InputProps={{ style: { color: '#fff' } }}
        name="contact-form"
      />
      <Button type="submit" variant="contained" className={classes.submitButton}>
        Submit
      </Button>
    </form>
  );
};

export default ContactForm;