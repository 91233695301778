import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Card, CardContent, CardMedia, Typography, CardActionArea } from '@mui/material';
import Orlando from '../../images/Orlando.jpg';
// import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles()(() => ({
  cardContent: {
    backgroundColor: '#adadad',
    position: 'relative',
  },
  cardContainer: {
    margin: '15px',
  },
  learnMoreButton: {
    width: '50%',
    position: 'absolute',
    top: '50%',
    left: '60%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1, 
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', 
    zIndex: 0,
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  overlayVisible: {
    opacity: 1,
  },
  learnButton: {
    backgroundColor: '#fff',
    color: '#000',
    '&:hover': {
      backgroundColor: '#adadad',
    },
  }
}));

const OrlandoCard = () => {
  const { classes } = useStyles();
//   const [isHovered, setIsHovered] = React.useState(false);

//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };

//   const navigate = useNavigate();
//   const handleLearnMoreClick = () => {
//     navigate('/Orlando');
//   };

  return (
    <Card
      sx={{ maxWidth: 400 }}
      className={classes.cardContainer}
    //   onMouseEnter={handleMouseEnter}
    //   onMouseLeave={handleMouseLeave}
    >
      <CardActionArea>
        <CardMedia component="img" height="300" image={Orlando} alt="card" />
        <CardContent sx={{ minHeight: 100 }} className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="div">
            Orlando
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Steady population growth, robust job market, high demand for rental properties, and attractive affordability, making it an appealing destination for both investors and homebuyers.
          </Typography>
          {/* {isHovered && (
            <CardActions className={classes.learnMoreButton}>
              <Button variant='contained' className={classes.learnButton} onClick={handleLearnMoreClick}>
                Learn More
              </Button>
            </CardActions>
          )} */}
          {/* <div className={`${classes.overlay} ${isHovered ? classes.overlayVisible : ''}`}></div> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default OrlandoCard;
